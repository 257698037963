<template>
  <v-card
    class="transparent fill-height d-flex justify-center align-center flex-column"
    width="100%"
    flat
  >
    <v-card-title class="titleText">
      {{ subscribeMessage }}
    </v-card-title>
    <v-btn
      rounded
      color="accent"
      :loading="isLoading"
      @click="confirm"
    >
      {{ subscribeBtnTitle }}
    </v-btn>
    <message-modal
      ref="messageModal"
      :message="confirmMessage"
    />
  </v-card>
</template>

<script>

import MessageModal from "@/components/popup-components/message-modal";
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ConfirmationPage',
  components: {MessageModal},
  data: () => ({
    isLoading: false,
    subscribeMessage: '',
    subscribeBtnTitle: '',
    subscriberID: '',
  }),

  async created () {
    this.subscriberID = this.$route.params.subID
    await this.checkSubStatus()
  },
  computed: {
    ...mapGetters('Subscribe', ['getConfirmMessage', 'getSubscriberData']),
    confirmMessage() {
      return `${this.getConfirmMessage}`
    },
    subscriberData(){
      return this.getSubscriberData
    }
  },
  methods: {
    ...mapActions('Subscribe', ['confirmSubscription', 'checkSubscriberStatus']),
    ...mapActions('Tools', [ 'showPopup']),

    async confirm () {
      try {
        this.isLoading = true
        await this.confirmSubscription({
          subID: this.subscriberID,
          status: this.subscriberData.status
        })
        this.subscriberData.status === 'PENDING' ? this.showPopup('Subscribed successfully') :
            this.showPopup('Unsubscribed successfully')
      } catch (err) {
        console.log(err)
        this.showPopup(err)
      } finally {
        this.isLoading = false
        await this.$router.push('/')

      }
    },

    async checkSubStatus () {
      try {
        await this.checkSubscriberStatus(this.subscriberID)
        if (this.subscriberData.status === 'PENDING'){
          this.subscribeMessage = 'Click The Subscribe Button To Confirm Your Subscription To The Status Page'
          this.subscribeBtnTitle = 'Subscribe'
        }
        else{
          this.subscribeMessage = 'Click The Unsubscribe Button To Stop All Notifications From The Status Page'
          this.subscribeBtnTitle = 'Unsubscribe'
        }

      } catch (err) {
        console.log(err)
        await this.$router.push('/')
      }
    }
  }
}
</script>
<style scoped lang="scss">

</style>
